import React from 'react';

import { Head } from '../../components/head/head';
import { Layout } from '../../components/layout/layout';
import { Logo } from './_logo';

export const Thoughts: React.FC = () => (
  <>
    <Head title="Thoughts & Writing" path="/thoughts" />
    <Layout logo={<Logo />}>
      <h1 className="page-intro">Thoughts & Writing</h1>
      <p>
        I don’t actually have any meaningful writing to put here, yet. I'm hoping if I make a spot
        for it, I'll be embarrassed enough into eventually writing something deep and meaningful in
        an attempt to fill the space.
      </p>
      <p>Either that, or I'll eventually lose enough shame to start posting smutty fanfiction.</p>
    </Layout>
  </>
);

export default Thoughts;
